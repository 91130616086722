







import { Component, Vue, Watch } from 'vue-property-decorator'
import FavouriteNotification from '@/components/FavouriteNotification.vue'
import { EFrom } from '@/models'

@Component({ components: { FavouriteNotification } })
export default class FavouriteNotificationList extends Vue {
  private eFrom = EFrom

  @Watch('$store.state.notification.favouriteCount')
  rebindFavorite() {
    ;(this.$refs.favouriteNotification as any).rebindFavorite()
  }
}
